<template>
    <LiefengContent>
        <template #title>{{ menuCodeObj.menuName || "村居委管理员激活情况统计" }}</template>
        <template #toolsbarRight>
            <Button :disabled="loading" style="margin-right: 20px" :type="orgOnlineStatus == '' ? 'primary' : ''" @click="checkStatus('')">全部</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 1 ? 'primary' : ''" @click="checkStatus(1)">全部示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 4 ? 'primary' : ''" @click="checkStatus(4)">市级示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 5 ? 'primary' : ''" @click="checkStatus(5)">区级示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 6 ? 'primary' : ''" @click="checkStatus(6)">街镇级示范社区</Button>

            <!-- <Select v-model="queryCommunityType" style="width: 200px; margin: 0 20px" @on-change="changeQuery">
                <Option :value="1">全部管理员数</Option>
                <Option :value="2">在职管理员数</Option>
                <Option :value="3">在职激活数</Option>
                <Option :value="4">在职未激活数</Option>
                <Option :value="5">昨日激活新增数</Option>
            </Select> -->
            <Button :disabled="loading" type="primary" style="margin: 0 20px" @click="getList">查询</Button>

            <Button :disabled="loading" :type="level == 3 ? 'info' : ''" @click="changeLevel(3)" style="margin: 0 0 0 50px">按区</Button>
            <Button :disabled="loading" :type="level == 4 ? 'info' : ''" @click="changeLevel(4)" style="margin: 0">按街道</Button>
            <Button :disabled="loading" :type="level == 5 ? 'info' : ''" @click="changeLevel(5)" style="margin: 0">按社区</Button>

            <Button :disabled="loading" type="error" style="margin: 0 0 0 50px" @click="Export">导出报表</Button>
            <Button type="error" style="margin-left: 10px" @click="changeBack" v-if="isOrg">返回上一级</Button>
        </template>
        <template #contentArea>
            <Form :label-colon="true" :inline="true" class="search" style="position: relative; margin: 5px 0; display: flex; justify-content: space-between">
                <div style="display: flex; width: 100%">
                    <LiefengCascaderCity
                        v-if="showCascader"
                        :disabled="disabled"
                        :orgLevel="level"
                        @changeCasader="changeCasader"
                        :resetNum="resetNum"
                        :orgCode="4401"
                        :width="200"
                        :isClear="true"
                    ></LiefengCascaderCity>

                    <div style="display: flex; justify-content: space-between; margin-left: auto">
                        <span style="margin-top: 7px; margin-left: 10px">更新时间：{{ nowTime1 + " 00:00:00" || "" }}</span>
                    </div>
                </div>
            </Form>

            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="isHidePage"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[50, 1000, 2000, 3000]"
                @hadlePageSize="hadlePageSize"
                :showsummary="false"
                :summaryData="summaryData"
                :showIndex="false"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "./children/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            orgOnlineStatus: 1, // 切换的状态
            level: 5, // 切换的级别
            columnName: 0, // 栏目数
            nowTime1: "", // 当前时间
            resetNum: 1, // 是否清空当前社区选择

            tableData: [],
            talbeColumns: [],
            loading: false,
            menuCodeObj: {},

            cascaderList: [],

            // 栏目点击参数

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "栏目路径",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                    minWidth: 80,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",

            onlineType: "",

            disabled: false, // 是否禁用选择器
            isHidePage: false,

            page: 1,
            pageSize: 50,
            total: 0,

            allTableData: [], //暂存全部信息
            showCascader: true,

            summaryData: {},

            allTableData: [],
            lastMsg: {},
            queryCommunityType: "",
            isOrg: false,

            isAllOrg: false,
            userButtonRoot:''
        }
    },
    async created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.buttonRoot =  userButtonRoot.includes("1001")
        ? "1001"
        : userButtonRoot.includes("1002")
        ? "1002"
        : userButtonRoot.includes("1003")
        ? "1003"
        : "";
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        await this.getByMenuCode()
        await this.getList()
        console.log('buttonRoot',this.buttonRoot);
    },
    methods: {
        searchAllOrg() {
            this.isAllOrg = !this.isAllOrg
            if (this.isAllOrg) {
                this.cascaderList = []
                this.queryCommunityType = ""
                this.orgOnlineStatus = 1
                this.onlineType = ""
            }
            this.getList()
        },
        changeBack() {
            this.queryCommunityType = ""
            // this.level = 5
            this.isOrg = false
            this.changeMarkColumn()
            if (this.level == 3) {
                this.cascaderList = []
            } else if (this.level == 4) {
                this.cascaderList.length = 1
            } else if (this.level == 5) {
                this.cascaderList.length = 2
            }
            this.getList()
        },
        changeOrgColumn() {
            this.tableData.pop()
            console.log("tableData", this.tableData)
            this.isOrg = true
            this.talbeColumns = [
                {
                    title: "序号",
                    key: "index",
                    align: "center",
                    width: 100,
                },
                {
                    title: "区",
                    key: "zoneName",
                    align: "center",
                    width: 100,
                },
                {
                    title: "街道",
                    key: "streetName",
                    align: "center",
                    width: 100,
                },
                {
                    title: "社区",
                    key: "communityName",
                    align: "center",
                    width: 100,
                },
                {
                    title: "姓名",
                    key: "staffName",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "账号",
                    key: "staffMobile",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "流程权限",
                    key: "flowCodess",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "在职状态",
                    key: "workStatus",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.workStatus && params.row.workStatus.indexOf("在职") > -1 ? "" : "red",
                                },
                            },
                            params.row.workStatus
                        )
                    },
                },
                {
                    title: "激活情况",
                    key: "activeStatus",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: params.row.activeStatus && params.row.activeStatus.indexOf("已激活") > -1 ? "" : "red",
                                },
                            },
                            params.row.activeStatus
                        )
                    },
                },
                {
                    title: "激活时间",
                    key: "activeDate",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.activeStatus && params.row.activeStatus.indexOf("已激活") > -1 ? params.row.activeDate : "-")
                    },
                },
            ]
        },
        changeMarkColumn() {
            let arr1 = []
            if (this.level == 3) {
                arr1 = [
                    {
                        title: "序号",
                        key: "index",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 100,
                    },
                ]
            } else if (this.level == 4) {
                arr1 = [
                    {
                        title: "序号",
                        key: "index",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 100,
                    },
                ]
            } else if (this.level == 5) {
                arr1 = [
                    {
                        title: "序号",
                        key: "index",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "社区",
                        key: "communityName",
                        align: "center",
                        width: 100,
                    },
                ]
            }
            let arr2 = [
                {
                    title: "全部管理员数",
                    key: "totalNum",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        if (!params.row.totalNum || params.row.totalNum == 0) return
                                        console.log("进来了", this.cascaderList)
                                        this.changeCode(params)
                                        this.queryCommunityType = 1
                                        console.log("获取参数", this.cascaderList)
                                        this.getList()
                                        this.changeOrgColumn()
                                    },
                                },
                            },
                            params.row.totalNum
                        )
                    },
                },
                {
                    title: "在职管理员数",
                    key: "onJobNum",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        if (!params.row.onJobNum || params.row.onJobNum == 0) return
                                        this.changeCode(params)
                                        this.queryCommunityType = 2
                                        this.getList()
                                        this.changeOrgColumn()
                                    },
                                },
                            },
                            params.row.onJobNum
                        )
                    },
                },
                {
                    title: "在职激活数",
                    key: "onJobActiveNum",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        if (!params.row.onJobActiveNum || params.row.onJobActiveNum == 0) return
                                        this.changeCode(params)
                                        this.queryCommunityType = 3
                                        this.getList()
                                        this.changeOrgColumn()
                                    },
                                },
                            },
                            params.row.onJobActiveNum
                        )
                    },
                },
                {
                    title: "在职未激活数",
                    key: "onJobUnActivatedNum",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        if (!params.row.onJobUnActivatedNum || params.row.onJobUnActivatedNum == 0) return
                                        this.changeCode(params)
                                        this.queryCommunityType = 4
                                        this.getList()
                                        this.changeOrgColumn()
                                    },
                                },
                            },
                            params.row.onJobUnActivatedNum
                        )
                    },
                },
                {
                    title: "昨日激活新增数",
                    key: "newActivatedNum",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        if (!params.row.newActivatedNum || params.row.newActivatedNum == 0) return
                                        this.changeCode(params)
                                        this.queryCommunityType = 5
                                        this.getList()
                                        this.changeOrgColumn()
                                    },
                                },
                            },
                            params.row.newActivatedNum
                        )
                    },
                },
            ]
            this.talbeColumns = [...arr1, arr2]
        },
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.tableData = []
            if(val.page == 1){
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice(0, this.page * this.pageSize)
            }else{
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice((this.page * this.pageSize) - (this.pageSize), this.page * this.pageSize)
            }
            if (this.level == "5") {
                // if(this.page != 1){
                this.tableData.push(this.lastMsg)
                // }
            }
        },
        changeQuery(val) {
            this.queryCommunityType = val
        },
        // 改变示范社区东西
        changeSelect(val) {
            if (val == undefined) return
            this.getList()
        },
        // 改变选择社区组件
        changeCasader(val) {
            this.cascaderList = val
            this.onlineType = ""
            if (!val.length) {
                this.resetNum++
                this.getList()
            }
            if (this.level == "3") {
                if (val.length == 1) this.getList()
            } else if (this.level == "4") {
                if (val.length == 2) this.getList()
            } else if (this.level == "5") {
                if (val.length == 3) this.getList()
            }
        },
        async checkStatus(val) {
            if (this.orgOnlineStatus == val) return
            if (this.isAllOrg) this.isAllOrg = false
            if (val == 4) {
                this.orgOnlineStatus = ""
                this.onlineType = 1
            } else if (val == 5) {
                this.orgOnlineStatus = ""
                this.onlineType = 2
            } else if (val == 6) {
                this.orgOnlineStatus = ""
                this.onlineType = 3
            }
            this.orgOnlineStatus = val
            this.isOrg = false
            await this.getList()
        },
        async changeLevel(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            this.cascaderList = []
            this.queryCommunityType = ""
            this.isOrg = false
            this.onlineType = ""
            await this.getList()
        },
        async changeLevel2(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            await this.getList()
        },

        //获取列表
        async getList(val) {
            let arr1 = []
            let arr2 = []
            this.disabled = true
            if (this.level != 5 && !this.isOrg) {
                this.isOrg = false
            }
            if (this.orgOnlineStatus != 2 && this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
                arr2 = [
                    {
                        title: "全部管理员数",
                        key: "totalNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.totalNum || params.row.totalNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 1
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.totalNum
                            )
                        },
                    },
                    {
                        title: "在职管理员数",
                        key: "onJobNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.onJobNum || params.row.onJobNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 2
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.onJobNum
                            )
                        },
                    },
                    {
                        title: "在职激活数",
                        key: "onJobActiveNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.onJobActiveNum || params.row.onJobActiveNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 3
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.onJobActiveNum
                            )
                        },
                    },
                    {
                        title: "在职未激活数",
                        key: "onJobUnActivatedNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.onJobUnActivatedNum || params.row.onJobUnActivatedNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 4
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.onJobUnActivatedNum
                            )
                        },
                    },
                    {
                        title: "昨日激活新增数",
                        key: "newActivatedNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.newActivatedNum || params.row.newActivatedNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 5
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.newActivatedNum
                            )
                        },
                    },
                ]
            } else if (this.orgOnlineStatus == 2 && this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                    arr2 = [
                        {
                            title: "全部管理员数",
                            key: "totalNum",
                            align: "center",
                            minWidth: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: "blue",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                        on: {
                                            click: async () => {
                                                if (!params.row.totalNum || params.row.totalNum == 0) return
                                                this.changeCode(params)
                                                this.queryCommunityType = 1
                                                this.getList()
                                                this.changeOrgColumn()
                                            },
                                        },
                                    },
                                    params.row.totalNum
                                )
                            },
                        },
                        {
                            title: "在职管理员数",
                            key: "onJobNum",
                            align: "center",
                            minWidth: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: "blue",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                        on: {
                                            click: async () => {
                                                if (!params.row.onJobNum || params.row.onJobNum == 0) return
                                                this.changeCode(params)
                                                this.queryCommunityType = 2
                                                this.getList()
                                                this.changeOrgColumn()
                                            },
                                        },
                                    },
                                    params.row.onJobNum
                                )
                            },
                        },
                        {
                            title: "在职激活数",
                            key: "onJobActiveNum",
                            align: "center",
                            minWidth: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: "blue",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                        on: {
                                            click: async () => {
                                                if (!params.row.onJobActiveNum || params.row.onJobActiveNum == 0) return
                                                this.changeCode(params)
                                                this.queryCommunityType = 3
                                                this.getList()
                                                this.changeOrgColumn()
                                            },
                                        },
                                    },
                                    params.row.onJobActiveNum
                                )
                            },
                        },
                        {
                            title: "在职未激活数",
                            key: "onJobUnActivatedNum",
                            align: "center",
                            minWidth: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: "blue",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                        on: {
                                            click: async () => {
                                                if (!params.row.onJobUnActivatedNum || params.row.onJobUnActivatedNum == 0) return
                                                this.changeCode(params)
                                                this.queryCommunityType = 4
                                                this.getList()
                                                this.changeOrgColumn()
                                            },
                                        },
                                    },
                                    params.row.onJobUnActivatedNum
                                )
                            },
                        },
                        {
                            title: "昨日激活新增数",
                            key: "newActivatedNum",
                            align: "center",
                            minWidth: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: "blue",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        },
                                        on: {
                                            click: async () => {
                                                if (!params.row.newActivatedNum || params.row.newActivatedNum == 0) return
                                                this.changeCode(params)
                                                this.queryCommunityType = 5
                                                this.getList()
                                                this.changeOrgColumn()
                                            },
                                        },
                                    },
                                    params.row.newActivatedNum
                                )
                            },
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
            } else if (this.level == 5) {
                arr1 = [
                    {
                        title: "序号",
                        key: "index",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "社区",
                        key: "communityName",
                        align: "center",
                        width: 100,
                    },
                ]
                arr2 = [
                    {
                        title: "全部管理员数",
                        key: "totalNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.totalNum || params.row.totalNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 1
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.totalNum
                            )
                        },
                    },
                    {
                        title: "在职管理员数",
                        key: "onJobNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.onJobNum || params.row.onJobNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 2
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.onJobNum
                            )
                        },
                    },
                    {
                        title: "在职激活数",
                        key: "onJobActiveNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.onJobActiveNum || params.row.onJobActiveNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 3
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.onJobActiveNum
                            )
                        },
                    },
                    {
                        title: "在职未激活数",
                        key: "onJobUnActivatedNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.onJobUnActivatedNum || params.row.onJobUnActivatedNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 4
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.onJobUnActivatedNum
                            )
                        },
                    },
                    {
                        title: "昨日激活新增数",
                        key: "newActivatedNum",
                        align: "center",
                        minWidth: 100,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            if (!params.row.newActivatedNum || params.row.newActivatedNum == 0) return
                                            this.changeCode(params)
                                            this.queryCommunityType = 5
                                            this.getList()
                                            this.changeOrgColumn()
                                        },
                                    },
                                },
                                params.row.newActivatedNum
                            )
                        },
                    },
                ]
            }
            this.talbeColumns = [...arr1, ...arr2]
            this.disabled = false
            this.loading = true
            let res = await this.$get("/gateway/api/symanage/pc/manages/selectOrgManagerDaily", {
                zoneCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[0] : "", //区
                streetCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[1] : "", // 街道
                communityCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[2] : "", // 社区
                onlineStatus: this.orgOnlineStatus == 4 || this.orgOnlineStatus == 5 || this.orgOnlineStatus == 6 ? "1" : this.orgOnlineStatus,
                level: this.level,
                onlineType: this.orgOnlineStatus == 4 ? "1" : this.orgOnlineStatus == 5 ? "2" : this.orgOnlineStatus == 6 ? "3" : "",
                queryDetail: this.queryCommunityType
            })
            if (res.code == 200) {
                this.loading = false
                this.tableData = res.dataList.map((item, index) => {
                    return {
                        ...item,
                        index: index + 1,
                        workStatus: item.workStatus && item.workStatus == "1" ? "在职" : "离职",
                        activeStatus: item.activeStatus && item.activeStatus == "1" ? "已激活" : "未激活",
                        registerDate: item.registerDate && item.registerDate != "" ? this.$core.formatDate(new Date(item.registerDate), "yyyy-MM-dd hh:mm:ss") : "",
                        activeDate:item.activeStatus && item.activeStatus == "1" ? this.$core.formatDate(new Date(item.activeDate),"yyyy-MM-dd"): '-',
                        flowCodess:this.folwCodefun(item.flowCode && item.flowCode!= '' ? item.flowCode.split(',') : '',)
                    }
                })
                console.log('this.tableData',this.tableData);
                let totalNum = 0,
                    onJobNum = 0,
                    onJobActiveNum = 0,
                    onJobUnActivatedNum = 0,
                    newActivatedNum = 0
                this.summaryData = {}
                var sums = {}
                this.tableData.map(item => {
                    totalNum = totalNum + item.totalNum
                    onJobNum = onJobNum + item.onJobNum
                    onJobActiveNum = onJobActiveNum + item.onJobActiveNum
                    onJobUnActivatedNum = onJobUnActivatedNum + item.onJobUnActivatedNum
                    newActivatedNum = newActivatedNum + item.newActivatedNum
                })
                this.allTableData = this.tableData
                if (this.level == 5) {
                    this.total = this.tableData.length
                    this.tableData = JSON.parse(JSON.stringify(this.allTableData)).splice(0, 50)
                }
                sums = {
                    index: "合计",
                    zoneName: "",
                    streetName: "",
                    communityName: "",
                    totalNum,
                    onJobNum,
                    onJobActiveNum,
                    onJobUnActivatedNum,
                    newActivatedNum,
                }
                this.lastMsg = sums
                if (this.tableData && this.tableData.length) this.tableData.push(sums)
                if (this.isOrg) {
                    this.changeOrgColumn()
                }
            } else {
                this.loading = false
                this.$Message.error({
                    content: res.decs,
                    background: true,
                })
            }
        },
        folwCodefun(codes){
            if(codes && codes.length){
                let list = []
                codes.map(item =>{
                    if(item.indexOf('1001') > -1) list.push('拟制')
                    else if(item.indexOf('1002') > -1) list.push('审核')
                    else if(item.indexOf('1003') > -1) list.push('发布')
                })
                if(list && list.length){
                    return list.join(',')
                }
                return ''
            }
        },
        // 获取columnCode
        async getByMenuCode() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        },
        //导出报表
        Export() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否导出村居委管理员激活情况统计",
                onOk: () => {
                    // this.$refs.tab.$refs.lftable.exportCsv({
                    //     filename: "社区培训后情况统计",
                    // })
                    var tHeader = []
                    var filterVal = []
                    var tableData = []
                    tableData = JSON.parse(JSON.stringify(this.allTableData))
                    if (this.isOrg) {
                        tHeader = ["区", "街道", "社区", "姓名", "账号","流程权限", "在职状态", "激活情况", "激活时间"]
                        filterVal = ["zoneName", "streetName", "communityName", "staffName", "staffMobile","flowCodess", "workStatus", "activeStatus", "activeDate"]
                        this.$core.exportExcel(tHeader, filterVal, tableData, "村居委管理员激活情况统计")
                        return
                    }
                    if (this.level == "3") {
                        tHeader = ["区", "全部管理员数	", "在职管理员数", "在职激活数", "在职未激活数", "昨日激活新增数"]
                        filterVal = ["zoneName", "totalNum", "onJobNum", "onJobActiveNum", "onJobUnActivatedNum", "newActivatedNum"]
                    } else if (this.level == "4") {
                        tHeader = ["区", "街道", "全部管理员数	", "在职管理员数", "在职激活数", "在职未激活数", "昨日激活新增数"]
                        filterVal = ["zoneName", "streetName", "totalNum", "onJobNum", "onJobActiveNum", "onJobUnActivatedNum", "newActivatedNum"]
                    } else if (this.level == "5") {
                        tHeader = ["区", "街道", "社区", "全部管理员数	", "在职管理员数", "在职激活数", "在职未激活数", "昨日激活新增数"]
                        filterVal = ["zoneName", "streetName", "communityName", "totalNum", "onJobNum", "onJobActiveNum", "onJobUnActivatedNum", "newActivatedNum"]
                        tableData.push(this.lastMsg)
                    }

                    this.$core.exportExcel(tHeader, filterVal, tableData, "村居委管理员激活情况统计")
                },
            })
        },
        changeCode(params) {
            if (this.level == 3) {
                this.cascaderList = [params.row.zoneCode]
            } else if (this.level == 4) {
                this.cascaderList = [params.row.zoneCode, params.row.streetCode]
            } else if (this.level == 5) {
                this.cascaderList = [params.row.zoneCode, params.row.streetCode, params.row.communityCode]
            }
        },
        changeBlankStatus(val) {
            this.blankStatus = val
        },
    },
}
</script>
    
<style scoped lang='less'>
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.ivu-form-item {
    margin-bottom: 5px;
    margin-right: 0px;
}
/deep/.ivu-table-sort {
    width: 26px;
    height: 13px;
    i {
        font-size: 27px;
    }
}
</style>